<template>
  <main>
    <div class="main-container" style="text-decoration: none;">
      <div class="container-all">
        <nav class="navbar navbar-expand-lg sticky-top navbar-light bg-light">
          <div class="container-fluid">
            <a class="navbar-brand">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="navbar-item">
                  <router-link class="nav-link fw-semibold" aria-current="page" to="/admin">General</router-link>
                </li>
                <li class="navbar-item">
                  <router-link class="nav-link" to="/admin/vacantes">Ver Vacantes</router-link>
                </li>
                <li class="navbar-item">
                  <router-link class="nav-link" to="/admin/historial-registro">
                    Historial Registros
                  </router-link>
                </li>
                <li class="navbar-item">
                  <router-link class="nav-link" to="/admin/historial-empresa">
                    Historial Empresas
                  </router-link>
                </li>
                <li class="navbar-item">
                  <router-link class="nav-link" to="/admin/registrosincompletos">
                    Registros Incompletos
                  </router-link>
                </li>
                <li class="navbar-item">
                  <router-link class="nav-link" to="/admin/universidades">
                    Universidades
                  </router-link>
                </li>
                <li class="navbar-item">
                  <router-link class="nav-link" to="/admin/verificacion">
                    Verificación
                  </router-link>
                </li>
                <li class="navbar-item">
                  <router-link class="nav-link" to="/admin/promotionalCode">
                    Códigos
                  </router-link>
                </li>
              </ul>
              <ul class="navbar-nav mb-2 mb-lg-0">
                <li><router-link class="dropdown-item" to="/" @click="logout()">Cerrar Sesión</router-link></li>
              </ul>
            </div>
          </div>
        </nav>
        <div class="J-admin-main">
          <router-view />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { AdminService, UserService } from "@/services";

export default {
  data: () => ({
    dataUser: {},
    dataCompany: {},
    dataVacante: undefined,
    dataUserTotal: {},
    imagen: "",
    imgmini: "",
    imageUrl: "/images/profile.jpg",
    response: {
      loading: false,
    },
    urlImage: "https://picsum.photos/1020/400",
  }),
  mounted() {
    this.dataUser = JSON.parse(localStorage.getItem("user") || null);
  },
  computed: {
    img() {
      return this.imgmini;
    },
  },
  methods: {
    image(e) {
      let file = e.target.files[0];
      this.imagen = file;
      this.modifeImagen(file);
    },
    modifeImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imgmini = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      this.removeItem("token");
    },
    removeItem(sKey, sPath, sDomain) {
      document.cookie =
        encodeURIComponent(sKey) +
        "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
        (sDomain ? "; domain=" + sDomain : "") +
        (sPath ? "; path=" + sPath : "");
    },
    async savedData(file) {
      try {
        this.response.loading = true;
        var resp = await UserService.savedImage({ file: file });
        var { data } = resp.data;
        var user = JSON.parse(localStorage.getItem("user") || null);
        user.photo = data;
        this.dataUser = user;
        this.response.loading = false;
        localStorage.setItem("user", JSON.stringify(user));
      } catch ($e) {
        this.response.loading = false;
      }
    },
    async getCompany() {
      try {
        var response = await AdminService.getCompany();
        var { data } = response.data;
        this.dataCompany = data;
        // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
    async getVacante() {
      try {
        var response = await AdminService.getVacantes();
        this.dataVacante = response.data;
        // eslint-disable-next-line no-empty
      } catch ($e) {

      }
    },
    async getUsers() {
      try {
        var response = await AdminService.getStudent();
        this.dataUserTotal = response.data;
        // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
    extractInformation() {
    },
  },
};
</script>
<style land="scss">
@import "../../styles/admin.scss";
</style>
